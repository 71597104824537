'use client';
import React from 'react';
import SomethingWrongImg from 'public/assets/something-wrong.svg';
import Button from './ui/Button';
import { cn } from '@/utils';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';

/**
 *
 * @param {Object} props
 * @param {Object} props.classes
 * @param {String} props.classes.container
 * @param {String} props.classes.image
 * @param {String} props.classes.header
 * @param {String} props.classes.btn
 * @param {Function} props.onRefresh
 * @returns
 */

export default function SomethingWrong({ onRefresh, classes }) {
  const CONTENT =
    LANGUAGES_CONTENT[StorageService.getLanguage()]['dialog'].error;
  return (
    <div
      className={cn(
        'py-6 flex flex-col items-center justify-center gap-4',
        classes?.container
      )}
    >
      <SomethingWrongImg className={cn('text-[300px]', classes?.image)} />
      <h1
        className={cn(
          'text-md font-semibold leading-none text-center',
          classes?.header
        )}
      >
        {CONTENT.title}
      </h1>
      <p
        className="text-sm text-center text-black/60"
        dangerouslySetInnerHTML={{ __html: CONTENT.description }}
      />
      <Button className={cn('', classes?.btn)} onClick={onRefresh}>
        {CONTENT.cta}
      </Button>
    </div>
  );
}
