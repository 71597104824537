import React from 'react';

/**
 * A custom React component with different size options.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {"medium" | "small" | "large"} props.size - The size of the component.
 *   Possible values: "medium", "small", "large".
 * @returns {JSX.Element} A React JSX element representing the component.
 * @example
 * // Example usage of the component
 * // Renders the component with a medium size
 * <Spacer size="medium" />
 */
export default function Spacer({ size }) {
  if (size === 'large') {
    return <div className="h-4" />;
  }
  if (size === 'small') {
    return <div className="h-1" />;
  }

  return <div className="h-2" />;
}
