import React from 'react';
import Skeleton from 'react-loading-skeleton';
import Spacer from '../ui/Spacer';

/**
 * A React component for offer Item
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Boolean} props.isLarge - The size of offer item.
 * @param {"medium" | "small" | "large"} props.spacerVariant - The size of the spacer component .
 *   Possible values: "medium", "small", "large".
 * @returns {JSX.Element} A React JSX element representing the component.
 */

export default function SkeletonOfferItem({ count = 1 }) {
  return (
    <>
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <div key={index} className="flex w-full gap-2">
            <Skeleton height={60} width={70} className="rounded-md" />
            <div className="flex flex-col mt-1">
              <p className="text-xs">
                <Skeleton width={150} />
              </p>
              <Spacer size="small" />
              <h2>
                <Skeleton width={120} />
              </h2>
            </div>
            <div className="my-auto ml-auto">
              <Skeleton width={101} height={32} className="rounded-sm " />
            </div>
          </div>
        ))}
    </>
  );
}

/*
  <div className="flex">
            <Skeleton
              className="h-[80px]  max-w-[80px]"
              containerClassName="flex-1"
              key={index}
            />
            <div className="flex flex-col gap-1">
              <Skeleton
                className="min-h-[10px] w-full"
                containerClassName="flex-1"
                key={index}
              />
              <Skeleton
                className="min-h-[25px] w-full"
                containerClassName="flex-1"
                key={index}
              />
            </div>
          </div> */

// export default function SkeletonOfferItem({ count = 1 }) {
//   return Array(count)
//     .fill(0)
//     .map((_, index) => (
//       <div
//         key={index}
//         className="flex items-start gap-3 px-3 py-4 border-b border-header-primary/50"
//       >
//         <Skeleton height={50} width={50} className="rounded-lg" />
//         <div className="flex flex-col">
//           <h2 className="text-md">
//             <Skeleton width={200} />
//           </h2>
//           <p className="text-xs">
//             <Skeleton width={50} />
//           </p>
//         </div>
//       </div>
//     ));
// }
